export default {
    83021: {
        name: 'Art Studio (ART)',
        lat: 33.650162,
        lng: -117.844887,
        imageURLs: ['463/art-studio.jpg'],
    },
    83022: {
        name: 'Art, Culture & Technology (ACT)',
        lat: 33.65057,
        lng: -117.844902,
        imageURLs: ['463/art-culture-and-technology.jpg'],
    },
    83023: {
        name: 'Arts Annex (ARAN)',
        lat: 33.646503,
        lng: -117.846962,
        imageURLs: ['463/arts-annex.jpg'],
    },
    83024: {
        name: 'Arts Instruction & Technology Resource Center (AITR)',
        lat: 33.649773,
        lng: -117.843979,
        imageURLs: ['463/arts-instruction-technology-resource-center.jpg'],
    },
    83025: {
        name: 'Choral Studio',
        lat: 33.649647,
        lng: -117.844063,
        imageURLs: ['463/choral-studio.jpg'],
    },
    83026: {
        name: 'Claire Trevor Theatre (CTT)',
        lat: 33.649578,
        lng: -117.845322,
        imageURLs: ['463/claire-trevor-theatre.jpg'],
    },
    83027: {
        name: 'Contemporary Arts Center (CAC)',
        lat: 33.650146,
        lng: -117.845322,
        imageURLs: ['463/contemporary-arts-center.jpg'],
    },
    83028: {
        name: 'Drama Building (DRA)',
        lat: 33.649738,
        lng: -117.845055,
        imageURLs: ['463/drama-building.jpg'],
    },
    83033: {
        name: 'Mesa Arts Building (MAB)',
        lat: 33.650208,
        lng: -117.846367,
        imageURLs: ['463/mesa-arts-building.jpg'],
    },
    83034: {
        name: 'Music & Media Building (MM)',
        lat: 33.649456,
        lng: -117.84446,
        imageURLs: ['463/music-and-media-building.jpg'],
    },
    83035: {
        name: 'William J. Gillespie Performance Studios (PSTU)',
        lat: 33.650654,
        lng: -117.845261,
        imageURLs: ['463/gillespie-performance-studios.jpg'],
    },
    83036: {
        name: 'Production Studio',
        lat: 33.649773,
        lng: -117.845772,
        imageURLs: ['463/production-studio.jpg'],
    },
    83037: {
        name: 'Robert Cohen Theatre & Dance Studios (DS)',
        lat: 33.649334,
        lng: -117.845573,
        imageURLs: ['463/robert-cohen-theatre.jpg'],
    },
    83038: {
        name: 'Sculpture & Ceramic Studios (SCS) | Nixon Theatre',
        lat: 33.650276,
        lng: -117.844521,
        imageURLs: ['463/sculpture-studio.jpg'],
    },
    83039: {
        name: 'Winifred Smith Hall (WSH)',
        lat: 33.649639,
        lng: -117.844475,
        imageURLs: ['463/winifred-smith-hall.jpg'],
    },
    83040: {
        name: 'Studio Four (STU4)',
        lat: 33.650143,
        lng: -117.845749,
        imageURLs: ['463/studio-four.jpg'],
    },
    83043: {
        name: 'Anteater Ballpark',
        lat: 33.651791,
        lng: -117.847626,
        imageURLs: ['463/anteater-ballpark.jpg'],
    },
    83045: {
        name: 'Anteater Stadium',
        lat: 33.650341,
        lng: -117.850945,
        imageURLs: ['463/anteater-stadium.jpg'],
    },
    83046: {
        name: 'Bren Events Center',
        lat: 33.649502,
        lng: -117.847168,
        imageURLs: ['463/bren-events-center.jpg'],
    },
    83048: {
        name: 'Crawford Clubhouse',
        lat: 33.647621,
        lng: -117.849213,
        imageURLs: ['463/crawford-clubhouse.jpg'],
    },
    83049: {
        name: 'Crawford Field',
        lat: 33.648949,
        lng: -117.84967,
        imageURLs: ['463/crawford-field.jpg'],
    },
    83050: {
        name: 'Crawford Hall (CH)',
        lat: 33.647907,
        lng: -117.848198,
        imageURLs: ['463/crawford-hall.jpg'],
    },
    83051: {
        name: 'Crawford Pool',
        lat: 33.648506,
        lng: -117.848038,
        imageURLs: ['463/crawford-pool.jpg'],
    },
    83053: {
        name: 'Intercollegiate Athletics Building (IAB)',
        lat: 33.648296,
        lng: -117.845695,
        imageURLs: ['463/intercollegiate-athletics-building.jpg'],
    },
    83054: {
        name: 'Anteater Tennis Stadium',
        lat: 33.649967,
        lng: -117.848,
        imageURLs: ['463/tennis-courts.jpg'],
    },
    83055: {
        name: 'Track & Field House',
        lat: 33.649975,
        lng: -117.849449,
        imageURLs: ['463/track-and-field-house.jpg'],
    },
    83056: {
        name: 'Vista Field',
        lat: 33.651073,
        lng: -117.849297,
        imageURLs: ['463/vista-field.jpg'],
    },
    83057: {
        name: 'Biological Sciences III (BS3)',
        lat: 33.645363,
        lng: -117.846222,
        imageURLs: ['463/biological-sciences-iii.jpg'],
    },
    83058: {
        name: 'Bison Modular (BIMO)',
        lat: 33.643814,
        lng: -117.84639,
        imageURLs: ['463/bison-modular.jpg'],
    },
    83059: {
        name: 'Bonney Research Laboratory (BRL)',
        lat: 33.646461,
        lng: -117.846169,
        imageURLs: ['463/bonney-research-laboratory.jpg'],
    },
    83060: {
        name: 'Center for the Neurobiology of Learning & Memory Annex (CNLM)',
        lat: 33.646629,
        lng: -117.846428,
        imageURLs: ['463/center-for-the-neurobiology-of-learning-and-memory-annex.jpg'],
    },
    83061: {
        name: 'Greenhouse',
        lat: 33.64732,
        lng: -117.845367,
        imageURLs: ['463/greenhouse.jpg'],
    },
    83062: {
        name: 'McGaugh Hall (MH)',
        lat: 33.645271,
        lng: -117.844887,
        imageURLs: ['463/mcgaugh-hall.jpg'],
    },
    83063: {
        name: 'Qureshey Research Laboratory (QRL)',
        lat: 33.646648,
        lng: -117.845764,
        imageURLs: ['463/qureshey-research-laboratory.jpg'],
    },
    83064: {
        name: 'Howard Schneiderman Lecture Hall (HSLH)',
        lat: 33.645618,
        lng: -117.844666,
        imageURLs: ['463/howard-schneiderman-lecture-hall.jpg'],
    },
    83065: {
        name: 'Steinhaus Hall (SH)',
        lat: 33.646347,
        lng: -117.844887,
        imageURLs: ['463/steinhaus-hall.jpg'],
    },
    83068: {
        name: 'Arroyo Vista',
        lat: 33.646317,
        lng: -117.827034,
        imageURLs: [],
    },
    83069: {
        name: 'Camino del Sol',
        lat: 33.644737,
        lng: -117.825172,
        imageURLs: [],
    },
    83070: {
        name: 'Campus Village',
        lat: 33.644444,
        lng: -117.847786,
        imageURLs: [],
    },
    83072: {
        name: 'Mesa Court ',
        lat: 33.651924,
        lng: -117.844528,
        imageURLs: [],
    },
    83073: {
        name: 'Middle Earth',
        lat: 33.644405,
        lng: -117.837173,
        imageURLs: [],
    },
    83074: {
        name: 'Palo Verde',
        lat: 33.641495,
        lng: -117.832947,
        imageURLs: [],
    },
    83075: {
        name: 'Puerta del Sol',
        lat: 33.647804,
        lng: -117.832581,
        imageURLs: [],
    },
    83076: {
        name: 'University Hills',
        lat: 33.637684,
        lng: -117.837753,
        imageURLs: [],
    },
    83077: {
        name: 'Verano Place',
        lat: 33.64497,
        lng: -117.832176,
        imageURLs: [],
    },
    83078: {
        name: 'Vista del Campo',
        lat: 33.640087,
        lng: -117.824104,
        imageURLs: [],
    },
    83079: {
        name: 'Vista del Campo Norte',
        lat: 33.6464,
        lng: -117.823601,
        imageURLs: [],
    },
    83081: {
        name: 'CAC G021 Arts Instructional Lab Classroom',
        lat: 33.650139,
        lng: -117.8452,
        imageURLs: [],
    },
    83083: {
        name: 'OIT@CCC Drop-In Lab',
        lat: 33.647816,
        lng: -117.841919,
        imageURLs: [],
    },
    83084: {
        name: 'Gateway Study Center Drop-In Lab',
        lat: 33.647617,
        lng: -117.841782,
        imageURLs: [],
    },
    83086: {
        name: 'OIT@SST Drop-In Lab',
        lat: 33.646374,
        lng: -117.840492,
        imageURLs: [],
    },
    83087: {
        name: 'OIT@Student Center Drop-In Lab',
        lat: 33.648624,
        lng: -117.842079,
        imageURLs: [],
    },
    83090: {
        name: 'Humanities Studio Labs',
        lat: 33.647305,
        lng: -117.844208,
        imageURLs: [],
    },
    83091: {
        name: 'Langson Library Labs',
        lat: 33.64727,
        lng: -117.840996,
        imageURLs: [],
    },
    83092: {
        name: 'MSTB 210 Instructional Lab Classroom',
        lat: 33.642155,
        lng: -117.844315,
        imageURLs: [],
    },
    83093: {
        name: 'NS1 2144 Instructional Lab Classroom',
        lat: 33.644836,
        lng: -117.845398,
        imageURLs: [],
    },
    83094: {
        name: 'Science Library Labs',
        lat: 33.646057,
        lng: -117.846626,
        imageURLs: [],
    },
    83095: {
        name: 'Donald Bren Hall (DBH)',
        lat: 33.643288,
        lng: -117.841965,
        imageURLs: ['463/donald-bren-hall.jpg'],
    },
    83096: {
        name: 'Information & Computer Science (ICS)',
        lat: 33.644344,
        lng: -117.84182,
        imageURLs: ['463/information-and-computer-science.jpg'],
    },
    83098: {
        name: 'Information & Computer Science 2 (ICS2)',
        lat: 33.643913,
        lng: -117.841713,
        imageURLs: ['463/information-and-computer-science-ii.jpg'],
    },
    83099: {
        name: 'Interim Classroom Facility (ICF)',
        lat: 33.644463,
        lng: -117.840012,
        imageURLs: ['463/interim-classroom-facility.jpg'],
    },
    83101: {
        name: 'Engineering & Computing Trailer (ECT)',
        lat: 33.644051,
        lng: -117.840149,
        imageURLs: ['463/engineering-and-comuting-trailer.jpg'],
    },
    83102: {
        name: 'Engineering Gateway (EG)',
        lat: 33.643215,
        lng: -117.840157,
        imageURLs: ['463/engineering-gateway.jpg'],
    },
    83103: {
        name: 'Engineering Hall (EH)',
        lat: 33.643799,
        lng: -117.841202,
        imageURLs: ['463/engineering-hall.jpg'],
    },
    83104: {
        name: 'Engineering Laboratory Facility (ELF)',
        lat: 33.643822,
        lng: -117.839622,
        imageURLs: ['463/engineering-laboratory-facility.jpg'],
    },
    83105: {
        name: 'Engineering Lecture Hall (ELH)',
        lat: 33.644459,
        lng: -117.840736,
        imageURLs: ['463/engineering-lecture-hall.jpg'],
    },
    83106: {
        name: 'Engineering Tower (ET)',
        lat: 33.644859,
        lng: -117.841202,
        imageURLs: ['463/engineering-tower.jpg'],
    },
    83108: {
        name: 'McDonnell Douglas Engineering Auditorium (MDE)',
        lat: 33.643967,
        lng: -117.840752,
        imageURLs: ['463/mcdonnell-douglas-engineering-auditorium.jpg'],
    },
    83109: {
        name: 'Rockwell Engineering Center (REC)',
        lat: 33.643944,
        lng: -117.840523,
        imageURLs: ['463/rockwell-engineering-center.jpg'],
    },
    83120: {
        name: "BC's Cavern",
        lat: 33.645988,
        lng: -117.844322,
        imageURLs: ['463/bcs-cavern-food-court.jpg'],
    },
    83122: {
        name: 'POD Social Sciences',
        lat: 33.64703,
        lng: -117.839737,
        imageURLs: ['463/1R7A6971.jpg'],
    },
    83123: {
        name: 'Cafe Espresso',
        lat: 33.643822,
        lng: -117.843452,
        imageURLs: ['463/cafe-espresso_1.jpg'],
    },
    83125: {
        name: 'The Green Room',
        lat: 33.64983,
        lng: -117.844772,
        imageURLs: ['463/green-room.jpg'],
    },
    83128: {
        name: 'Phoenix Food Court',
        lat: 33.645481,
        lng: -117.840729,
        imageURLs: ['463/phoenix-food-court.jpg'],
    },
    83130: {
        name: 'Starbucks @ Biological Sciences',
        lat: 33.64497,
        lng: -117.845543,
        imageURLs: ['463/starbucks-bio.jpg'],
    },
    83131: {
        name: 'University Club (UN CLB)',
        lat: 33.642914,
        lng: -117.842545,
        imageURLs: ['463/university-club.jpg'],
    },
    83134: {
        name: 'Beckman Laser Institute (BLI)',
        lat: 33.644192,
        lng: -117.849915,
        imageURLs: ['463/beckman-laser-institute.jpg'],
    },
    83136: {
        name: 'Gillespie Neuroscience Research Facility (GNRF)',
        lat: 33.644009,
        lng: -117.851265,
        imageURLs: ['463/gillespie-neuroscience-research-facility.jpg'],
    },
    83137: {
        name: 'Gottschalk Medical Plaza',
        lat: 33.646935,
        lng: -117.851173,
        imageURLs: ['463/gottschalk-medical-plaza.jpg'],
    },
    83139: {
        name: 'Hewitt Research Hall (HRH)',
        lat: 33.643505,
        lng: -117.851921,
        imageURLs: ['463/hewitt-hall.jpg'],
    },
    83140: {
        name: 'Irvine Hall (IH)',
        lat: 33.645077,
        lng: -117.852577,
        imageURLs: ['463/irvine-hall.jpg'],
    },
    83141: {
        name: 'Medical Education Building',
        lat: 33.64463,
        lng: -117.852066,
        imageURLs: ['463/medical-education-building.jpg'],
    },
    83142: {
        name: 'Medical Sciences A (MS-A)',
        lat: 33.645065,
        lng: -117.849831,
        imageURLs: ['463/medical-sciences-a.jpg'],
    },
    83143: {
        name: 'Medical Sciences A Administration',
        lat: 33.644836,
        lng: -117.849945,
        imageURLs: ['463/medical-sciences-a-administration.jpg'],
    },
    83144: {
        name: 'Medical Sciences A Annex',
        lat: 33.644779,
        lng: -117.850403,
        imageURLs: ['463/medical-sciences-a-annex.jpg'],
    },
    83145: {
        name: 'Medical Sciences B (MS-B)',
        lat: 33.645443,
        lng: -117.849846,
        imageURLs: ['463/medical-sciences-b.jpg'],
    },
    83146: {
        name: 'Medical Sciences C (MS-C)',
        lat: 33.645458,
        lng: -117.85041,
        imageURLs: ['463/medical-sciences-c.jpg'],
    },
    83147: {
        name: 'Medical Sciences Classroom Facility',
        lat: 33.645313,
        lng: -117.852371,
        imageURLs: ['463/medical-sciences-classroom-facility.jpg'],
    },
    83148: {
        name: 'Medical Sciences D (MS-D) | Cheney Hall',
        lat: 33.645409,
        lng: -117.850945,
        imageURLs: ['463/medical-sciences-d-cheney-building.jpg'],
    },
    83149: {
        name: 'Medical Sciences E (MS-E)',
        lat: 33.645481,
        lng: -117.851509,
        imageURLs: ['463/medical-sciences-e.jpg'],
    },
    83150: {
        name: 'Medical Surge I (MS1)',
        lat: 33.646484,
        lng: -117.850174,
        imageURLs: ['463/medical-surge-i.jpg'],
    },
    83151: {
        name: 'Medical Surge II (MS2)',
        lat: 33.646976,
        lng: -117.850288,
        imageURLs: ['463/medical-surge-ii.jpg'],
    },
    83152: {
        name: 'MRI 3T Trailer',
        lat: 33.645267,
        lng: -117.852165,
        imageURLs: ['463/mri-3t-trailer.jpg'],
    },
    83153: {
        name: 'MRI 4T Trailer',
        lat: 33.645508,
        lng: -117.852386,
        imageURLs: [],
    },
    83155: {
        name: 'Sprague Hall (SPH)',
        lat: 33.644062,
        lng: -117.852066,
        imageURLs: ['463/sprague-hall.jpg'],
    },
    83156: {
        name: 'Tamkin Student Lecture Building',
        lat: 33.645622,
        lng: -117.851982,
        imageURLs: ['463/tamkin-student-lecture-building.jpg'],
    },
    83160: {
        name: 'Humanities Gateway (HG)',
        lat: 33.648266,
        lng: -117.844406,
        imageURLs: ['463/humanities-gateway.jpg'],
    },
    83161: {
        name: 'Humanities Hall (HH)',
        lat: 33.647411,
        lng: -117.844017,
        imageURLs: ['463/humanities-hall.jpg'],
    },
    83162: {
        name: 'Humanities Instructional Building (HIB)',
        lat: 33.648476,
        lng: -117.843918,
        imageURLs: ['463/humanities-instructional-building.jpg'],
    },
    83163: {
        name: 'Humanities Interim Classroom Facility (HICF) | Studio Art Trailer',
        lat: 33.646919,
        lng: -117.84687,
        imageURLs: ['463/humanities-interim-classroom-facility.jpg'],
    },
    83165: {
        name: 'Krieger Hall (KH)',
        lat: 33.647736,
        lng: -117.843521,
        imageURLs: ['463/murray-krieger-hall.jpg'],
    },
    83166: {
        name: 'Law Building (LAW)',
        lat: 33.64687,
        lng: -117.835938,
        imageURLs: ['463/law.jpg'],
    },
    83167: {
        name: 'Multipurpose Academic & Administrative Building (MPAA)',
        lat: 33.647156,
        lng: -117.837013,
        imageURLs: ['463/multipurpose-academic-and-admin-building.jpg'],
    },
    83168: {
        name: 'Science Library (SLIB)',
        lat: 33.645908,
        lng: -117.846848,
        imageURLs: ['463/science-library.jpg'],
    },
    83169: {
        name: 'Gateway Study Center (GC)',
        lat: 33.647388,
        lng: -117.841873,
        imageURLs: ['463/gateway-study-center.jpg'],
    },
    83170: {
        name: 'Langson Library (LLIB)',
        lat: 33.646961,
        lng: -117.841316,
        imageURLs: ['463/langson-library.jpg'],
    },
    83371: {
        name: 'Croul Hall (CRH)',
        lat: 33.643715,
        lng: -117.844681,
        imageURLs: ['463/croul-hall.jpg'],
    },
    83372: {
        name: 'Multipurpose Science & Technology Building (MSTB)',
        lat: 33.64209,
        lng: -117.844467,
        imageURLs: ['463/multipurpose-science-and-technology.jpg'],
    },
    83373: {
        name: 'Natural Sciences I (NS1)',
        lat: 33.644348,
        lng: -117.845886,
        imageURLs: ['463/natural-sciences-i.jpg'],
    },
    83374: {
        name: 'Natural Sciences II (NS2)',
        lat: 33.644054,
        lng: -117.845413,
        imageURLs: ['463/natural-sciences-ii.jpg'],
    },
    83375: {
        name: 'Parkview Classroom Building (PCB)',
        lat: 33.644463,
        lng: -117.842743,
        imageURLs: ['463/parkview-classroom-building.jpg'],
    },
    83376: {
        name: 'Physical Sciences Classroom Building (PSCB)',
        lat: 33.643417,
        lng: -117.843544,
        imageURLs: ['463/physical-sciences-classroom-building.jpg'],
    },
    83377: {
        name: 'Physical Sciences High Bay',
        lat: 33.64362,
        lng: -117.845291,
        imageURLs: ['463/physical-sciences-high-bay.jpg'],
    },
    83378: {
        name: 'Physical Sciences Lecture Hall (PSLH)',
        lat: 33.643456,
        lng: -117.844009,
        imageURLs: ['463/physical-sciences-lecture-hall.jpg'],
    },
    83379: {
        name: 'Frederick Reines Hall (FRH)',
        lat: 33.644302,
        lng: -117.843452,
        imageURLs: ['463/reines-hall.jpg'],
    },
    83380: {
        name: 'Rowland Hall (RH)',
        lat: 33.644531,
        lng: -117.844162,
        imageURLs: ['463/rowland-hall.jpg'],
    },
    83384: {
        name: 'Continuing Education 4',
        lat: 33.645981,
        lng: -117.837387,
        imageURLs: ['463/continuing-education-4.jpg'],
    },
    83385: {
        name: 'Continuing Education 3',
        lat: 33.64579,
        lng: -117.837463,
        imageURLs: ['463/continuing-education-3.jpg'],
    },
    83386: {
        name: 'Continuing Education 5',
        lat: 33.64526,
        lng: -117.836304,
        imageURLs: ['463/continuing-education-5.jpg'],
    },
    83387: {
        name: 'Continuing Education 6',
        lat: 33.645065,
        lng: -117.835938,
        imageURLs: ['463/continuing-education-6.jpg'],
    },
    83544: {
        name: 'Social Ecology I (SE)',
        lat: 33.646194,
        lng: -117.838844,
        imageURLs: ['463/social-ecology-i.jpg'],
    },
    83545: {
        name: 'Social Ecology II (SE2)',
        lat: 33.64666,
        lng: -117.839043,
        imageURLs: ['463/social-ecology-ii.jpg'],
    },
    83551: {
        name: 'Cross-Cultural Center (CRCC)',
        lat: 33.647915,
        lng: -117.841881,
        imageURLs: ['463/cross-cultural-center.jpg'],
    },
    83552: {
        name: 'Disability Services Center',
        lat: 33.644142,
        lng: -117.840469,
        imageURLs: ['463/disability-services-center.jpg'],
    },
    83558: {
        name: 'KUCI Radio Station',
        lat: 33.646389,
        lng: -117.847198,
        imageURLs: ['463/kuci-radio-station.jpg'],
    },
    83559: {
        name: 'Student Center (SC)',
        lat: 33.649323,
        lng: -117.842255,
        imageURLs: ['463/student-center_1.jpg'],
    },
    83560: {
        name: 'Student Health Center (SHC)',
        lat: 33.64555,
        lng: -117.836014,
        imageURLs: ['463/student-health-center.jpg'],
    },
    83561: {
        name: 'Student Services I (SS1)',
        lat: 33.647888,
        lng: -117.842316,
        imageURLs: ['463/student-services-i.jpg'],
    },
    83565: {
        name: 'Social & Behavioral Sciences Gateway (SBSG)',
        lat: 33.647488,
        lng: -117.839165,
        imageURLs: ['463/social-and-behavioral-sciences-gateway.jpg'],
    },
    83566: {
        name: 'Social Science Hall (SSH)',
        lat: 33.646236,
        lng: -117.840073,
        imageURLs: ['463/social-science-hall.jpg'],
    },
    83567: {
        name: 'Social Science Laboratory (SSL)',
        lat: 33.646015,
        lng: -117.840019,
        imageURLs: ['463/social-science-lab.jpg'],
    },
    83568: {
        name: 'Social Science Lecture Hall (SSLH)',
        lat: 33.647274,
        lng: -117.83979,
        imageURLs: ['463/social-science-lecture-hall.jpg'],
    },
    83569: {
        name: 'Social Science Plaza A (SSPA)',
        lat: 33.647034,
        lng: -117.839584,
        imageURLs: ['463/social-science-plaza-a.jpg'],
    },
    83570: {
        name: 'Social Science Plaza B (SSPB)',
        lat: 33.647083,
        lng: -117.839096,
        imageURLs: ['463/social-science-plaza-b.jpg'],
    },
    83571: {
        name: 'Social Science Tower (SST)',
        lat: 33.646591,
        lng: -117.840294,
        imageURLs: ['463/social-science-tower.jpg'],
    },
    83572: {
        name: 'Social Science Trailer (SSTR)',
        lat: 33.647007,
        lng: -117.840263,
        imageURLs: ['463/social-science-trailer.jpg'],
    },
    83609: {
        name: '100 Theory',
        lat: 33.644245,
        lng: -117.855675,
        imageURLs: [],
    },
    83611: {
        name: '111 Theory',
        lat: 33.642071,
        lng: -117.855331,
        imageURLs: [],
    },
    83612: {
        name: '5251 California',
        lat: 33.638294,
        lng: -117.851685,
        imageURLs: [],
    },
    83615: {
        name: '101 Academy',
        lat: 33.648136,
        lng: -117.854164,
        imageURLs: [],
    },
    83617: {
        name: 'Administrative Modular',
        lat: 33.642017,
        lng: -117.846245,
        imageURLs: ['463/administrative-modular.jpg'],
    },
    83618: {
        name: 'Aldrich Hall (ALH)',
        lat: 33.648518,
        lng: -117.841263,
        imageURLs: ['463/aldrich-hall.jpg'],
    },
    83622: {
        name: 'Faculty and Staff Housing Office',
        lat: 33.635952,
        lng: -117.835968,
        imageURLs: [],
    },
    83623: {
        name: 'Mesa Office Building (MOB)',
        lat: 33.651299,
        lng: -117.846451,
        imageURLs: ['463/mesa-office-building.jpg'],
    },
    83624: {
        name: 'Newkirk Alumni Center',
        lat: 33.65247,
        lng: -117.846298,
        imageURLs: ['463/newkirk-alumni-center.jpg'],
    },
    83625: {
        name: 'Public Services (PSB)',
        lat: 33.646313,
        lng: -117.83493,
        imageURLs: ['463/public-services.jpg'],
    },
    83628: {
        name: 'University Tower (UT)',
        lat: 33.650928,
        lng: -117.838409,
        imageURLs: ['463/university-tower-2.jpg'],
    },
    99610: {
        name: 'Interim Classroom Facility (ICF)',
        lat: 33.644463,
        lng: -117.840012,
        imageURLs: ['463/interim-classroom-facility.jpg'],
    },
    101047: {
        name: 'University Hills Community Center',
        lat: 33.636307,
        lng: -117.835823,
        imageURLs: [],
    },
    114575: {
        name: 'Faculty Research Facility (FRF)',
        lat: 33.66433,
        lng: -117.854301,
        imageURLs: [],
    },
    114578: {
        name: 'Arboretum (ARBO)',
        lat: 33.663853,
        lng: -117.853256,
        imageURLs: ['463/Capture1.PNG'],
    },
    114579: {
        name: 'California Institute for Telecommunications and Information Technology (Calit2)',
        lat: 33.643063,
        lng: -117.841217,
        imageURLs: ['463/california-institute-for-telecommunications-and-information-technology.jpg'],
    },
    114583: {
        name: 'Merage School of Business II (SB2)',
        lat: 33.64669,
        lng: -117.838112,
        imageURLs: ['463/business-ii.jpg'],
    },
    114585: {
        name: '100 Theory',
        lat: 33.644245,
        lng: -117.855675,
        imageURLs: [],
    },
    114586: {
        name: 'Anteater Instruction & Research Building (AIRB)',
        lat: 33.643009,
        lng: -117.838013,
        imageURLs: ['463/anteater-instruction-and-research-building.jpg'],
    },
    114588: {
        name: 'Beckman Center of the National Academies of Sciences & Engineering',
        lat: 33.649414,
        lng: -117.854378,
        imageURLs: ['463/beckman-center-of-the-national-academies.jpg'],
    },
    114589: {
        name: 'Berk Hall (BH) | Nursing Science',
        lat: 33.646294,
        lng: -117.84951,
        imageURLs: ['463/berk-hall.jpg'],
    },
    114590: {
        name: 'Farm School/Red Barn',
        lat: 33.642284,
        lng: -117.828911,
        imageURLs: ['463/farm-school-red-barn.jpg'],
    },
    114594: {
        name: 'Bonney Research Laboratory (BRL)',
        lat: 33.646461,
        lng: -117.846169,
        imageURLs: ['463/bonney-research-laboratory.jpg'],
    },
    114595: {
        name: 'Qureshey Research Laboratory (QRL)',
        lat: 33.646648,
        lng: -117.845764,
        imageURLs: ['463/qureshey-research-laboratory.jpg'],
    },
    114596: {
        name: 'Center for the Neurobiology of Learning & Memory Annex (CNLM)',
        lat: 33.646629,
        lng: -117.846428,
        imageURLs: ['463/center-for-the-neurobiology-of-learning-and-memory-annex.jpg'],
    },
    114597: {
        name: 'Greenhouse',
        lat: 33.64732,
        lng: -117.845367,
        imageURLs: ['463/greenhouse.jpg'],
    },
    114598: {
        name: 'Merage School of Business (SB1)',
        lat: 33.647072,
        lng: -117.838158,
        imageURLs: ['463/business-i.jpg'],
    },
    114599: {
        name: 'Education Building (EDUC)',
        lat: 33.647308,
        lng: -117.835861,
        imageURLs: ['463/education.jpg'],
    },
    114600: {
        name: 'Engineering Gateway (EG)',
        lat: 33.643215,
        lng: -117.840157,
        imageURLs: ['463/engineering-gateway.jpg'],
    },
    114601: {
        name: 'Engineering Laboratory Facility (ELF)',
        lat: 33.643822,
        lng: -117.839622,
        imageURLs: ['463/engineering-laboratory-facility.jpg'],
    },
    114602: {
        name: 'Little Theatre',
        lat: 33.647213,
        lng: -117.844063,
        imageURLs: ['463/little-theatre.jpg'],
    },
    114603: {
        name: 'Humanities Interim Classroom Facility (HICF) | Studio Art Trailer',
        lat: 33.646919,
        lng: -117.84687,
        imageURLs: ['463/humanities-interim-classroom-facility.jpg'],
    },
    114604: {
        name: 'Multipurpose Academic & Administrative Building (MPAA)',
        lat: 33.647156,
        lng: -117.837013,
        imageURLs: ['463/multipurpose-academic-and-admin-building.jpg'],
    },
    114605: {
        name: 'Beckman Laser Institute (BLI)',
        lat: 33.644192,
        lng: -117.849915,
        imageURLs: ['463/beckman-laser-institute.jpg'],
    },
    114606: {
        name: 'Gillespie Neuroscience Research Facility (GNRF)',
        lat: 33.644009,
        lng: -117.851265,
        imageURLs: ['463/gillespie-neuroscience-research-facility.jpg'],
    },
    114607: {
        name: 'Sue & Bill Gross Hall: A CIRM Institute',
        lat: 33.643555,
        lng: -117.851501,
        imageURLs: ['463/gross-hall.jpg'],
    },
    114608: {
        name: 'Hewitt Research Hall (HRH)',
        lat: 33.643505,
        lng: -117.851921,
        imageURLs: ['463/hewitt-hall.jpg'],
    },
    114609: {
        name: 'Plumwood House (PH)',
        lat: 33.644718,
        lng: -117.851295,
        imageURLs: ['463/plumwood-house.jpg'],
    },
    114610: {
        name: 'Gavin Herbert Eye Institute',
        lat: 33.641743,
        lng: -117.852249,
        imageURLs: ['463/gavin-herbert-eye-institute.jpg'],
    },
    114611: {
        name: 'Steinhaus Hall (SH)',
        lat: 33.646347,
        lng: -117.844887,
        imageURLs: ['463/steinhaus-hall.jpg'],
    },
    114612: {
        name: 'Bison Modular (BIMO)',
        lat: 33.643814,
        lng: -117.84639,
        imageURLs: ['463/bison-modular.jpg'],
    },
    114613: {
        name: 'Multipurpose Science & Technology Building (MSTB)',
        lat: 33.64209,
        lng: -117.844467,
        imageURLs: ['463/multipurpose-science-and-technology.jpg'],
    },
    114614: {
        name: 'Physical Sciences High Bay',
        lat: 33.64362,
        lng: -117.845291,
        imageURLs: ['463/physical-sciences-high-bay.jpg'],
    },
    114615: {
        name: 'Rowland Hall (RH)',
        lat: 33.644531,
        lng: -117.844162,
        imageURLs: ['463/rowland-hall.jpg'],
    },
    114616: {
        name: 'Anteater Instruction & Research Building (AIRB)',
        lat: 33.643009,
        lng: -117.838013,
        imageURLs: ['463/anteater-instruction-and-research-building.jpg'],
    },
    114618: {
        name: 'Sprague Hall (SPH)',
        lat: 33.644062,
        lng: -117.852066,
        imageURLs: ['463/sprague-hall.jpg'],
    },
    114621: {
        name: 'Facilities Management Building',
        lat: 33.664715,
        lng: -117.854805,
        imageURLs: [],
    },
    114622: {
        name: 'Humanities Gateway (HG)',
        lat: 33.648266,
        lng: -117.844406,
        imageURLs: ['463/humanities-gateway.jpg'],
    },
    114623: {
        name: 'Social Ecology I (SE)',
        lat: 33.646194,
        lng: -117.838844,
        imageURLs: ['463/social-ecology-i.jpg'],
    },
    114624: {
        name: 'Student Center (SC)',
        lat: 33.649323,
        lng: -117.842255,
        imageURLs: ['463/student-center_1.jpg'],
    },
    114626: {
        name: 'Student Health Center II',
        lat: 33.645878,
        lng: -117.834938,
        imageURLs: ['463/student-health-center-ii.jpg'],
    },
    114628: {
        name: 'Court Building',
        lat: 33.643574,
        lng: -117.826813,
        imageURLs: ['463/court-building.jpg'],
    },
    114629: {
        name: 'Field Building',
        lat: 33.642227,
        lng: -117.82605,
        imageURLs: ['463/field-building.jpg'],
    },
    114630: {
        name: 'Anteater Recreation Center (ARC)',
        lat: 33.643494,
        lng: -117.828194,
        imageURLs: ['463/anteater-recreation-center.jpg'],
    },
    114659: {
        name: 'Irvine Barclay Theatre',
        lat: 33.649208,
        lng: -117.84079,
        imageURLs: ['463/irvine-barclay-theatre.jpg'],
    },
    114858: {
        name: 'Social & Behavioral Sciences Gateway (SBSG)',
        lat: 33.647488,
        lng: -117.839165,
        imageURLs: ['463/social-and-behavioral-sciences-gateway.jpg'],
    },
    114877: {
        name: 'Biological Sciences III (BS3)',
        lat: 33.645363,
        lng: -117.846222,
        imageURLs: ['463/biological-sciences-iii.jpg'],
    },
    114878: {
        name: 'Social Science Tower (SST)',
        lat: 33.646591,
        lng: -117.840294,
        imageURLs: ['463/social-science-tower.jpg'],
    },
    114879: {
        name: 'Gavin Herbert Eye Institute',
        lat: 33.641743,
        lng: -117.852249,
        imageURLs: ['463/gavin-herbert-eye-institute.jpg'],
    },
    114880: {
        name: 'Child Development School (CD)',
        lat: 33.662647,
        lng: -117.856941,
        imageURLs: [],
    },
    114881: {
        name: 'Early Childhood Education Center',
        lat: 33.647472,
        lng: -117.830025,
        imageURLs: ['463/early-childhood-education-center.jpg'],
    },
    114882: {
        name: "Children's Center",
        lat: 33.644539,
        lng: -117.831406,
        imageURLs: ['463/childrens-center.jpg'],
    },
    114883: {
        name: 'Extended Day Center',
        lat: 33.644623,
        lng: -117.830742,
        imageURLs: ['463/extended-day-center.jpg'],
    },
    114884: {
        name: 'Infant Toddler Center I',
        lat: 33.646759,
        lng: -117.829735,
        imageURLs: ['463/infant-toddler-center-i.jpg'],
    },
    114885: {
        name: 'Infant Toddler Center II',
        lat: 33.646465,
        lng: -117.829727,
        imageURLs: ['463/infant-toddler-center-ii.jpg'],
    },
    114887: {
        name: 'Verano Preschool',
        lat: 33.645748,
        lng: -117.833092,
        imageURLs: ['463/verano-preschool-2.jpg'],
    },
    114888: {
        name: 'University Montessori School',
        lat: 33.640659,
        lng: -117.836967,
        imageURLs: [],
    },
    114889: {
        name: 'Continuing Education 1 (DCE)',
        lat: 33.645115,
        lng: -117.834862,
        imageURLs: ['463/continuing-education-1.jpg'],
    },
    114890: {
        name: 'American Heart Association',
        lat: 33.648422,
        lng: -117.830582,
        imageURLs: ['463/american-heart-association.jpg'],
    },
    114891: {
        name: 'Yurt Dance Studio',
        lat: 33.641994,
        lng: -117.827988,
        imageURLs: ['463/yurt-dance-studio.jpg'],
    },
    114958: {
        name: '20/20 Cafe',
        lat: 33.641762,
        lng: -117.85257,
        imageURLs: ['463/20-20-cafe_1.jpg'],
    },
    114959: {
        name: 'Au Bon Pain',
        lat: 33.647003,
        lng: -117.83773,
        imageURLs: ['463/au-bon-pain.jpg'],
    },
    114960: {
        name: 'Espress Yourself',
        lat: 33.646988,
        lng: -117.835953,
        imageURLs: ['463/espress-yourself.jpg'],
    },
    114961: {
        name: 'Starbucks @ Paul Merage School of Business',
        lat: 33.647022,
        lng: -117.838463,
        imageURLs: ['463/starbucks-merage.jpg'],
    },
    114962: {
        name: 'Java City Kiosk',
        lat: 33.643463,
        lng: -117.841209,
        imageURLs: ['463/java-city.jpg'],
    },
    114963: {
        name: 'Med Ed Cafe',
        lat: 33.644859,
        lng: -117.851868,
        imageURLs: ['463/meded-cafe.jpg'],
    },
    115040: {
        name: 'Neuropsychiatric Center',
        lat: 33.78817,
        lng: -117.890266,
        imageURLs: [],
    },
    115041: {
        name: 'Douglas Hospital',
        lat: 33.78883,
        lng: -117.888664,
        imageURLs: [],
    },
    115042: {
        name: 'University Hospital Tower',
        lat: 33.788181,
        lng: -117.889244,
        imageURLs: [],
    },
    115043: {
        name: 'Manchester Pavilion',
        lat: 33.788429,
        lng: -117.89373,
        imageURLs: [],
    },
    115044: {
        name: 'Faculty / Administration Offices',
        lat: 33.787849,
        lng: -117.88752,
        imageURLs: [],
    },
    115045: {
        name: 'Pavilion 3',
        lat: 33.786591,
        lng: -117.889809,
        imageURLs: [],
    },
    115046: {
        name: 'Chao Family Comprehensive Cancer Center',
        lat: 33.787182,
        lng: -117.889122,
        imageURLs: [],
    },
    115047: {
        name: 'Imaging Services',
        lat: 33.786411,
        lng: -117.888481,
        imageURLs: [],
    },
    115048: {
        name: 'Burn & Wound Therapy',
        lat: 33.786552,
        lng: -117.886711,
        imageURLs: [],
    },
    115049: {
        name: 'Pavilion 4',
        lat: 33.786366,
        lng: -117.889153,
        imageURLs: [],
    },
    115051: {
        name: 'H.H. Chao Comprehensive Digestive Disease Center (CDDC)',
        lat: 33.787128,
        lng: -117.888618,
        imageURLs: [],
    },
    115052: {
        name: 'Grunigen Medical Library',
        lat: 33.787151,
        lng: -117.888298,
        imageURLs: [],
    },
    115053: {
        name: 'Laboratory Medicine Building',
        lat: 33.787575,
        lng: -117.887161,
        imageURLs: [],
    },
    115054: {
        name: 'Parking & Security',
        lat: 33.786041,
        lng: -117.888527,
        imageURLs: [],
    },
    115055: {
        name: 'Pavilion 1',
        lat: 33.786579,
        lng: -117.89048,
        imageURLs: [],
    },
    115056: {
        name: 'Nikken Center',
        lat: 33.786755,
        lng: -117.888359,
        imageURLs: [],
    },
    115057: {
        name: 'Pavilion 2',
        lat: 33.787083,
        lng: -117.890457,
        imageURLs: [],
    },
    115058: {
        name: 'Orthopaedics',
        lat: 33.787079,
        lng: -117.8899,
        imageURLs: [],
    },
    115059: {
        name: 'Visitor Parking',
        lat: 33.786003,
        lng: -117.889748,
        imageURLs: [],
    },
    115060: {
        name: 'Edward Shanbrom, MD Hall',
        lat: 33.787067,
        lng: -117.887161,
        imageURLs: [],
    },
    115061: {
        name: 'Central Plant',
        lat: 33.786175,
        lng: -117.888016,
        imageURLs: [],
    },
    115062: {
        name: 'Facilities Services',
        lat: 33.786671,
        lng: -117.888054,
        imageURLs: [],
    },
    115063: {
        name: 'Administrative / Academic Offices',
        lat: 33.786469,
        lng: -117.887291,
        imageURLs: [],
    },
    115064: {
        name: 'Home Dialysis',
        lat: 33.786621,
        lng: -117.886368,
        imageURLs: [],
    },
    123779: {
        name: 'Courtyard Study Lounge Drop-In Lab',
        lat: 33.648525,
        lng: -117.841957,
        imageURLs: [],
    },
    126143: {
        name: 'Watson Bridge',
        lat: 33.649155,
        lng: -117.839882,
        imageURLs: ['463/watson-bridge.jpg'],
    },
    126144: {
        name: 'Aldrich Flag Poles',
        lat: 33.648033,
        lng: -117.840858,
        imageURLs: ['463/1R7A7588.jpg'],
    },
    126145: {
        name: 'Ring Mall',
        lat: 33.643509,
        lng: -117.842789,
        imageURLs: [],
    },
    126146: {
        name: 'Aldrich Park',
        lat: 33.645905,
        lng: -117.842766,
        imageURLs: ['463/1R7A2387.jpg'],
    },
    126147: {
        name: 'Arts Bridge',
        lat: 33.649048,
        lng: -117.84465,
        imageURLs: ['463/1R7A7687.jpg'],
    },
    126148: {
        name: 'Physical Sciences Plaza',
        lat: 33.643879,
        lng: -117.844109,
        imageURLs: ['463/physical-sciences-plaza.jpg'],
    },
    126149: {
        name: 'Biological Sciences Plaza',
        lat: 33.645901,
        lng: -117.845505,
        imageURLs: ['463/biological-sciences-plaza.jpg'],
    },
    126150: {
        name: 'Engineering Plaza',
        lat: 33.644058,
        lng: -117.841026,
        imageURLs: ['463/engineering-plaza.jpg'],
    },
    126151: {
        name: 'Social Sciences Plaza',
        lat: 33.646309,
        lng: -117.8396,
        imageURLs: ['463/1R7A7521.jpg'],
    },
    126152: {
        name: 'Student Center Plaza',
        lat: 33.648251,
        lng: -117.842361,
        imageURLs: ['463/student-center-plaza.jpg'],
    },
    126153: {
        name: 'Humanities Plaza',
        lat: 33.647991,
        lng: -117.843994,
        imageURLs: ['463/humanities-plaza.jpg'],
    },
    126154: {
        name: 'Bostick Bridge',
        lat: 33.645851,
        lng: -117.848755,
        imageURLs: ['463/1R7A7774.jpg'],
    },
    126155: {
        name: 'Athletics Bridge',
        lat: 33.647491,
        lng: -117.847549,
        imageURLs: ['463/1R7A7733.jpg'],
    },
    126156: {
        name: 'Alumni Grove',
        lat: 33.64463,
        lng: -117.842155,
        imageURLs: ['463/1R7A7532_copy.jpg'],
    },
    126157: {
        name: 'Anteater Statue',
        lat: 33.649094,
        lng: -117.845985,
        imageURLs: ['463/1R7A7719.jpg'],
    },
    126158: {
        name: 'Laurel L. Wilkening Rose Garden',
        lat: 33.648605,
        lng: -117.840355,
        imageURLs: ['463/rose-garden.jpg'],
    },
    126159: {
        name: 'Cesario Memorial',
        lat: 33.64381,
        lng: -117.851677,
        imageURLs: ['463/cesario-memorial.jpg'],
    },
    126999: {
        name: 'Bren Center Flag Poles',
        lat: 33.649712,
        lng: -117.846176,
        imageURLs: ['463/bren-center-flag-poles.jpg'],
    },
    127000: {
        name: 'Claire Trevor Oscar/Emmy Display',
        lat: 33.649529,
        lng: -117.845062,
        imageURLs: ['463/1R7A7876.jpg'],
    },
    127001: {
        name: 'Mesa Parking Bridge',
        lat: 33.650322,
        lng: -117.84597,
        imageURLs: ['463/1R7A7702.jpg'],
    },
    127052: {
        name: 'Student Center Courtyard Stage',
        lat: 33.648708,
        lng: -117.842484,
        imageURLs: ['463/student-center-courtyard-stage.jpg'],
    },
    127246: {
        name: 'Palo Verde Bridge',
        lat: 33.643524,
        lng: -117.83567,
        imageURLs: ['463/palo-verde-bridge.jpg'],
    },
    171183: {
        name: '120 Theory',
        lat: 33.643787,
        lng: -117.857338,
        imageURLs: [],
    },
    186008: {
        name: 'Student Center Bridge',
        lat: 33.649696,
        lng: -117.843185,
        imageURLs: ['463/1R7A7660.jpg'],
    },
    186011: {
        name: 'Continuing Education Bridge',
        lat: 33.645115,
        lng: -117.835289,
        imageURLs: ['463/1R7A7517.jpg'],
    },
    186012: {
        name: 'Bridge Cafe',
        lat: 33.645115,
        lng: -117.835037,
        imageURLs: ['463/bridge-cafe.jpg'],
    },
    186013: {
        name: 'The Anteatery',
        lat: 33.651157,
        lng: -117.845146,
        imageURLs: ['463/anteatery.jpg'],
    },
    197840: {
        name: 'Summer Session A',
        lat: 33.646507,
        lng: -117.837372,
        imageURLs: ['463/summer-session-a.jpg'],
    },
    197841: {
        name: 'Summer Session B',
        lat: 33.646229,
        lng: -117.837486,
        imageURLs: ['463/summer-session-b.jpg'],
    },
    255739: {
        name: '131 Innovation',
        lat: 33.646271,
        lng: -117.858429,
        imageURLs: [],
    },
    255740: {
        name: '141 Innovation',
        lat: 33.645462,
        lng: -117.858414,
        imageURLs: [],
    },
    255878: {
        name: 'Gottschalk P.O.D. Express',
        lat: 33.646748,
        lng: -117.850998,
        imageURLs: ['463/gottschalk-pod.jpg'],
    },
    255880: {
        name: 'Student Center',
        lat: 33.648888,
        lng: -117.8424,
        imageURLs: ['463/student-center_2.jpg'],
    },
    255884: {
        name: 'Anteater Learning Pavilion (ALP)',
        lat: 33.647213,
        lng: -117.844521,
        imageURLs: [],
    },
    255992: {
        name: 'Anteater Community Resource Center',
        lat: 33.653828,
        lng: -117.843697,
        imageURLs: ['463/mesa-dining-trailer.jpg'],
    },
    255997: {
        name: 'Student Services II (SS2)',
        lat: 33.648098,
        lng: -117.842384,
        imageURLs: ['463/student-services-ii.jpg'],
    },
    257153: {
        name: 'Engineering Computing Labs',
        lat: 33.643848,
        lng: -117.841278,
        imageURLs: [],
    },
    257154: {
        name: 'Grunigen Medical Library Lab',
        lat: 33.78706,
        lng: -117.888237,
        imageURLs: [],
    },
    257155: {
        name: 'MSTB 226 Instructional Lab Classroom',
        lat: 33.642063,
        lng: -117.844315,
        imageURLs: [],
    },
    257156: {
        name: 'NS1 3112 Instructional Lab Classroom',
        lat: 33.644733,
        lng: -117.845573,
        imageURLs: [],
    },
    264222: {
        name: 'Taza',
        lat: 33.651119,
        lng: -117.845245,
        imageURLs: ['463/taza.jpg'],
    },
    363319: {
        name: 'ALP 2800 Computer Focused Classroom',
        lat: 33.646881,
        lng: -117.844589,
        imageURLs: [],
    },
    363320: {
        name: 'ALP 3600 Computer Focused Classroom',
        lat: 33.647373,
        lng: -117.84449,
        imageURLs: [],
    },
    363321: {
        name: 'ALP 3610 Computer Focused Classroom',
        lat: 33.647156,
        lng: -117.844681,
        imageURLs: [],
    },
    364042: {
        name: 'Student Services West',
        lat: 33.646072,
        lng: -117.849152,
        imageURLs: [],
    },
    364043: {
        name: 'Student Services West',
        lat: 33.646072,
        lng: -117.849152,
        imageURLs: [],
    },
    407361: {
        name: 'ANTrepreneur Center',
        lat: 33.64621,
        lng: -117.837051,
        imageURLs: ['463/banning-house.jpg'],
    },
    407362: {
        name: 'Gateway Study Center (GC)',
        lat: 33.647388,
        lng: -117.841873,
        imageURLs: ['463/gateway-study-center.jpg'],
    },
    444368: {
        name: 'Plaza Verde',
        lat: 33.648224,
        lng: -117.828522,
        imageURLs: [],
    },
    444501: {
        name: 'Building Services Building',
        lat: 33.642532,
        lng: -117.848503,
        imageURLs: ['463/building-services-building.jpg'],
    },
    444502: {
        name: 'Central Plant (CP)',
        lat: 33.648022,
        lng: -117.846802,
        imageURLs: ['463/central-plant.jpg'],
    },
    444503: {
        name: 'Electrical Substation',
        lat: 33.64045,
        lng: -117.849808,
        imageURLs: ['463/electrical-substation.jpg'],
    },
    444504: {
        name: 'Facilities Management Shops',
        lat: 33.663635,
        lng: -117.85421,
        imageURLs: [],
    },
    444505: {
        name: 'Grounds Maintenance Facility',
        lat: 33.64283,
        lng: -117.848709,
        imageURLs: ['463/grounds-maintenance-facility.jpg'],
    },
    444506: {
        name: 'Facilities Management Building',
        lat: 33.66478,
        lng: -117.854935,
        imageURLs: [],
    },
    444507: {
        name: 'Passport Services ',
        lat: 33.664795,
        lng: -117.854523,
        imageURLs: [],
    },
    444508: {
        name: 'Receiving',
        lat: 33.664047,
        lng: -117.85524,
        imageURLs: [],
    },
    444509: {
        name: 'Recycling Center',
        lat: 33.663353,
        lng: -117.855057,
        imageURLs: [],
    },
    444510: {
        name: 'Thermal Energy Storage Tank',
        lat: 33.648575,
        lng: -117.846184,
        imageURLs: [],
    },
    445384: {
        name: 'Air Pollution Health Effects Laboratory (APHEL)',
        lat: 33.664837,
        lng: -117.853844,
        imageURLs: [],
    },
    446803: {
        name: 'Brandywine',
        lat: 33.645412,
        lng: -117.839455,
        imageURLs: ['463/middle-earth-towers-10_3.jpg'],
    },
    447392: {
        name: 'Faculty Research Facility',
        lat: 33.66433,
        lng: -117.854271,
        imageURLs: [],
    },
    451260: {
        name: '5270 California',
        lat: 33.638107,
        lng: -117.849129,
        imageURLs: [],
    },
    454485: {
        name: 'University Art Gallery (UAG) | Beall Center for Art + Technology',
        lat: 33.64991,
        lng: -117.844414,
        imageURLs: [],
    },
    454486: {
        name: 'Medical Surge I (MS1)',
        lat: 33.646484,
        lng: -117.850174,
        imageURLs: ['463/medical-surge-i.jpg'],
    },
    454487: {
        name: 'Donald Bren Hall (DBH)',
        lat: 33.643288,
        lng: -117.841965,
        imageURLs: ['463/donald-bren-hall.jpg'],
    },
    454488: {
        name: 'Engineering Hall (EH)',
        lat: 33.643799,
        lng: -117.841202,
        imageURLs: ['463/engineering-hall.jpg'],
    },
    454489: {
        name: 'Air Pollution Health Effects Laboratory (APHEL)',
        lat: 33.664837,
        lng: -117.853844,
        imageURLs: [],
    },
    454490: {
        name: 'Student Center South',
        lat: 33.648724,
        lng: -117.842705,
        imageURLs: [],
    },
    454491: {
        name: 'Student Center South',
        lat: 33.648724,
        lng: -117.842705,
        imageURLs: [],
    },
    454492: {
        name: 'Jao Family Sculpture Garden',
        lat: 33.647762,
        lng: -117.843025,
        imageURLs: ['463/Jao_Family_Sculpture.jpg'],
    },
    454493: {
        name: 'Science Library Mesquite Garden',
        lat: 33.645844,
        lng: -117.847435,
        imageURLs: ['463/1R7A7736.jpg'],
    },
    454494: {
        name: 'Anteater Plaza',
        lat: 33.647785,
        lng: -117.841087,
        imageURLs: [],
    },
    454495: {
        name: 'Founders Court',
        lat: 33.646801,
        lng: -117.844566,
        imageURLs: ['463/1R7A7683.jpg'],
    },
    464544: {
        name: 'Campus Village Community Center',
        lat: 33.64484,
        lng: -117.847015,
        imageURLs: [],
    },
    464545: {
        name: 'Campus Village Housing Office',
        lat: 33.645222,
        lng: -117.846962,
        imageURLs: [],
    },
    464546: {
        name: 'Mesa Court Community Center',
        lat: 33.652782,
        lng: -117.844353,
        imageURLs: [],
    },
    464547: {
        name: 'Mesa Court Housing Office',
        lat: 33.652988,
        lng: -117.845322,
        imageURLs: [],
    },
    464548: {
        name: 'Arroyo Vista Community Center & Housing Office',
        lat: 33.646748,
        lng: -117.829056,
        imageURLs: [],
    },
    464549: {
        name: 'Middle Earth Housing Office',
        lat: 33.644669,
        lng: -117.837456,
        imageURLs: [],
    },
    464550: {
        name: 'Palo Verde Housing Office',
        lat: 33.641926,
        lng: -117.833199,
        imageURLs: [],
    },
    464551: {
        name: 'Palo Verde Community Center',
        lat: 33.642132,
        lng: -117.832954,
        imageURLs: [],
    },
    464552: {
        name: 'Verano Place Housing Office',
        lat: 33.644211,
        lng: -117.830315,
        imageURLs: [],
    },
    471175: {
        name: 'Infinity Fountain',
        lat: 33.644646,
        lng: -117.843575,
        imageURLs: ['463/1R7A7542.jpg'],
    },
    471176: {
        name: 'Stacey Nicholas Tech Pavilion',
        lat: 33.644524,
        lng: -117.841461,
        imageURLs: ['463/1R7A7835.jpg'],
    },
    471177: {
        name: 'Willed Body Program Dedication Monument',
        lat: 33.645966,
        lng: -117.850487,
        imageURLs: ['463/1R7A7889.jpg'],
    },
    471178: {
        name: 'Schonfeld Fountain',
        lat: 33.646858,
        lng: -117.839348,
        imageURLs: ['463/1R7A8018.jpg'],
    },
    471180: {
        name: 'Maya Lin Water Table',
        lat: 33.649628,
        lng: -117.84491,
        imageURLs: ['463/1R7A7912.jpg'],
    },
    471202: {
        name: 'Gloria Gellman Sculpture',
        lat: 33.648666,
        lng: -117.843788,
        imageURLs: ['463/1R7A7978.jpg'],
    },
};
